<template>
    <v-container>
        <v-card max-width="360" min-height="200" class="ma-auto d-flex flex-column" v-if="!isError">
            <v-card-title>คำเชิญเข้า {{ company.name }}</v-card-title>

            <v-card-text class="text-center">                                
                <v-img :src="company.profile"></v-img>                
            </v-card-text>
            
            <v-card-actions class="mt-auto">
                <v-btn 
                block                
                color="primary"
                :loading="isLoading"
                @click="join"
                :disabled="isJoined"
                >{{ !isJoined ? 'เข้าร่วม' : 'ท่านเข้าร่วมไปแล้ว'}}</v-btn>                
            </v-card-actions>
        </v-card>
        <div v-else class="d-flex flex-column">
          <h2 class="text-center mt-4">
            ลิ้งค์นี้อาจหมดอายุ หรือ ผ่านการใช้งานแล้ว โปรดตรวจสอบกับคนที่ส่งลิ้งให้ท่านอีกครั้ง
          </h2>

          <v-btn
            class="mx-auto"
            @click="$router.push({ name: 'companies' })"
            color="primary"
          >
              ย้อนกลับ
          </v-btn>
        </div>
        <v-dialog
          v-model="isShowDialog"
          persistent
          max-width="300"
        >
          <v-card>
            <v-card-title>โปรดเข้าสู่ระบบ</v-card-title>

            <v-card-text>
              ท่านยังไม่ได้เข้าสู่ระบบ หรือ สมัครสมาชิก โปรดเข้าสู่ระบบก่อนเข้าร่วม
            </v-card-text>
            
            <v-card-actions>
              <v-btn
                block
                @click="register"
                color="primary"
              >เข้าสู่ระบบ / สมัครสมาชิก</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { asyncGet, asyncPost } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isLoggedIn: false,
      isLoading: false,
      isJoined: false,
      company: false,
      role: false,
      isError: false,
      isShowDialog: false,
    }
  },
  created() {
    this.getInvitationByUUID(this.$route.params.uuid)
  },
  methods: {
    async getInvitationByUUID(uuid) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      this.isLoggedIn = this.$store.getters['auth/isLogin']

      try {
        const { invitation, isJoined } = await asyncGet('/invitation/' + uuid)

        const { company, role } = invitation

        this.company = company
        this.role = role
        this.isJoined = isJoined
      } catch (error) {
        this.isError = true
        console.log(error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async join() {
      if (!this.isLoggedIn) {
        this.isShowDialog = true
        return
      }
      const uuid = this.$route.params.uuid

      this.isLoading = true

      try {
        await asyncPost('/invitation/' + uuid, { WRUHERE: 'go away' })

        this.$router.push({ name: 'companies' })
      } catch (error) {
        console.log(error)
        this.isError = true
      }

      this.isLoading = false
    },
    register() {
      this.$router.push({ name: 'auth-login', query: { redirectTo: window.location.href } })
    },
  },
}
</script>